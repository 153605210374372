import React from "react";
import { db } from "../../firebase/firebase";
import { getDocs, query, collection, orderBy, limit } from "firebase/firestore";

function ReviewFromDoc(doc) {
    const data = doc.data();
    return {
        id: doc.id,
        rating: data.rating,
        text: data.text,
        createdAt: new Date(data.createdAt.seconds * 1000),
        createdBy: data.createdBy,
        isAnonymous: data.isAnonymous,
        name: data.name
    }
}


export default class ReviewFetchModel {
    static async fetchReviews(uid) {
        try {
            const docRef = query(collection(db, "user", uid, "reviews"), orderBy("createdAt", "desc"), limit(100));
            const docSnapshot = await getDocs(docRef);
            try {
                let reviews = docSnapshot.docs.map((doc) => ReviewFromDoc(doc));
                return {
                    success: true,
                    data: reviews
                }
            } catch (error) {
                return {
                    success: false,
                    error: error
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }
}
