import React, { useEffect } from "react";
import "../../index.css"
import Card from '@mui/material/Card';
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export default function Listing(props) {
    const ticketWords = props.item.tickets.length === 1 ? "ticket" : "tickets";
    const location = useLocation();

    const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    ...theme.typography.body2,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none",
    display: "flex",
    alignSelf: "center",
    height: "100%"
    }));

    useEffect(() => {
        if ( location.hash.length > 0 && location.hash.slice(1) === props.id) {
            props.handleSelect(props.item);
        }
    }, []);

    return (
        <Card className="listing" variant="outlined" sx={{ minWidth: "330px" }} id={props.id} onClick={() => props.handleSelect(props.item)}>
            <Grid container >
                <Grid item  sm={5}  xs={4}>
                    <Item>
                        <h2 className="event-face-title">{props.item.tickets.length}x {!props.item.eventTitle ? ticketWords : ""}</h2>
                    </Item>
                </Grid>
                <Grid item sm={5} xs={5}>
                    <Item>
                        <span className="listing-type">{props.item.type || props.item.eventTitle}</span>
                    </Item>
                </Grid>
                <Grid item sm={2} xs={3}>
                    <Item>
                        <span className="event-face-ticket_no">£{props.item.rawPrice}</span>
                    </Item>
                </Grid>
            </Grid>
        </Card>
    )
}