import "../index.css"
import React from "react";
import SearchBar from "./Search/SearchBar";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import EventFace from "./EventFace";
import FloatingFeedbackButton from "./FloatingFeedbackButton";
import { config } from "..";

export default function Discover({searchClient}) {
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flexGrow: 1,
        boxShadow: "none"
      }));

      const handleFeedbackClick = () => {
        // Add functionality to open a feedback form, redirect to another page, etc.
        window.open(config.feedback_url, '_blank');
      };


    return (
        <Container maxWidth="md" sx={{ minHeight: '100vh'}}>
            <Stack spacing={2}>
                <Item>
                    <h1 className="hero-title-coloured-p montserrat"> Buy & Sell Nightclub Tickets Here</h1>
                    <h2 className="hero-p discover-description">Buy nightclub tickets without the hassle of joining 5 different group chats...</h2>
                </Item>
                <Item>
                    <SearchBar searchClient={searchClient} hitComponent={EventFace}/>
                </Item>
            </Stack>
            <FloatingFeedbackButton handleFeedbackClick={handleFeedbackClick} />
        </Container>
    )

}