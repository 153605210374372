import React from "react";
import { CalendarIcon, LocationDot, TicketIcon } from "./Icons/Icons";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Highlight } from 'react-instantsearch';
import "../index.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function EventFace({ hit }) {
    const timeString = new Date(hit.start).toLocaleString().slice(0, -3);
    let tickets = hit.tickets > 0 ? (hit.tickets) : "Sold out";
    const navigate = useNavigate();

    return (
        <a href={"/event/" + hit.objectID} style={{ width: '100%'}} onMouseDown={() => navigate('/event/' + hit.objectID)}>
            <Card variant='outlined' className="eventface" sx={{ display: 'flex'}}>
                <Grid container>
                    <Grid item xs={9} md={10} sx={{ width: "100%" }}>
                        <CardContent sx={{ flex: '1 0 auto', textAlign: 'initial' }}>
                            <Typography component="div" variant="h5" fontSize="20px">
                                <Highlight attribute="name" hit={hit} />
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                            <span className="o"><CalendarIcon/></span> {timeString}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                            <span className="o"><LocationDot/></span> <Highlight attribute="venue" hit={hit} />
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <div className="ticket-no">
                            <span className="event-face-ticket_no">{tickets !== "Sold out" ? tickets : tickets} {tickets !== "Sold out" ? <TicketIcon/> : null}</span>
                        </div>
                    </Grid>
                    <Grid item xs={1} md={0}></Grid>
                </Grid>
            </Card>
        </a>
        )
}