import React from "react";
import { Fab, Tooltip } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";

const FloatingFeedbackButton = ({ handleFeedbackClick }) => {
  return (
    <Tooltip title="Give Feedback" arrow>
      <Fab
        color="primary"
        aria-label="feedback"
        onClick={handleFeedbackClick}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
        }}
      >
        <FeedbackIcon />
      </Fab>
    </Tooltip>
  );
};

export default FloatingFeedbackButton;
