import { updateDoc, getDoc, doc, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase/firebase";
import { deleteUser, getAuth, sendEmailVerification } from "firebase/auth";
import ListingPostModel from "./postData/ListingPostModel";


export default class Authenticator {

    static async firstListing(uid) {
        try {
            if (!uid) {
                return {
                    success: false,
                    error: "No uid provided"
                }
            }

            await setDoc(doc(db, "user", uid), {
                isListed: true,
            }, { merge: true });


            return {
                success: true,
                error: null
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async getUserName(uid) {
        try {
            const userDoc = await getDoc(doc(db, "user", uid));
            const userData = userDoc.data();
            if (userData) {
                return {
                    success: true,
                    data: {
                        username: userData.username,
                        avgRating: userData.avgRating,
                        numReviews: userData.numReviews
                    }
                }
            } else {
                return {
                    success: false,
                    error: "User data not found"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async getUserData(user) {
        try {
            if (user) {
                const userDoc = await getDoc(doc(db, "user", user.uid));
                const userData = userDoc.data();
                if (userData) {
                    let payouts_enabled = false;
                    if (userData.connectedAccount !== undefined) {
                        payouts_enabled = Boolean(userData.connectedAccount.payouts_enabled);
                    }
                    let first_listing = false;
                    if (userData.isListed !== undefined) {
                        first_listing = Boolean(userData.isListed);
                    }
                    return {
                        success: true,
                        data: {
                            termsAccepted: userData.termsAccepted,
                            email: userData.email,
                            payouts_enabled: payouts_enabled,
                            connectedAccountId: userData.connectedAccountId,
                            stripeCustomerId: userData.stripeCustomerId,
                            avgRating: userData.avgRating,
                            numReviews: userData.numReviews,
                            firstListing: first_listing,
                            username: userData.username
                        }
                    }
                } else {
                    return {
                        success: false,
                        error: "User data not found"
                    }
                }
            } else {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error getting user data"
            }
        }
    }

    static async isSeller(user) {
        try {
            const response = await this.getUserData(user);
            if (response.success) {
                return response.data.payouts_enabled;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }


    static async acceptTerms(user) {
        try {
            if (user) {
                await updateDoc(doc(db, "user", user.uid), {
                    termsAccepted: true
                });
                return {
                    success: true
                }
            } else {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error accepting terms"
            }
        }
    }

    static async emailVerification(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }

            const auth = getAuth();
            sendEmailVerification(auth.currentUser).then(() => {
                return {
                    success: true
                }
            }).catch((error) => {
                return {
                    success: false,
                    error: error
                }
            })

            return {
                success: true
            }

        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async deleteAccount() {
        // This is working
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            return {
                success: false,
                error: "User not signed in"
            }
        }

        const cartRes = await ListingPostModel.emptyCart(user);
        if (!cartRes.success) {
            return {
                success: false,
                error: "Failed to empty cart"
            }
        }

        const func = getFunctions();

        const userRef = await getDoc(doc(db, "user", user.uid));
        const { connectedAccountId } = userRef.data();
        try {
            if (connectedAccountId !== "" && connectedAccountId !== null && connectedAccountId !== undefined) {
                const res = await httpsCallable(func, 'deleteConnectedAccount')({ id: connectedAccountId });
                if (!res.data.deleted) {
                    return {
                        success: false,
                        error: "Failed to delete connected account. This is likely due to a pending balance"
                    }
                } else {
                    await setDoc(doc(db, "user", user.uid), {
                        connectedAccountId: ""
                    }, { merge: true });
                }
            } 
        }catch {
                return {
                    success: false,
                    error: "Failed to delete connected account"
                }
            }


        try {
            const response = await deleteUser(user)
            return {
                success: true,
                data: response
            }
        } catch (error) {
            return {
                success: false,
                error: "error deleting user"
            }
        }
    }
}