import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebase/auth";
import ListingFetchModel from "../../models/getData/ListingFetchModel";
import ListingPostModel from "../../models/postData/ListingPostModel";
import Transaction from "../../models/postData/Transaction";
import Skeleton from "../Skeleton/Skeleton";
import "./Cart.css";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Card, CardContent, Typography, Tooltip, Chip } from '@mui/material';
import { AccessTime } from '@mui/icons-material';

export default function Cart({ handleShowToast }) {
    const { authUser, isLoading } = useAuth();
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [cart, setCart] = useState([{
        event: "",
        eventStart: "",
        eventTitle: "",
        listing: "",
        price: 0,
        tickets: []
    }]);
    const [total, setTotal] = useState(0);
    const [fee, setFee] = useState(0);

    const getCart = async () => {
        ListingFetchModel.fetchCartItems(authUser).then((response) => {
            if (response.success) {
                setCart(response.data);
                setTotal(response.total);
                setFee(response.fee);
                checkTime(response.data);
            }
            setLoading(false);
        });
    };

    function handleRefresh() {
        setRefresh(!refresh);
    }

    const checkTime = async (cartItems) => {
        const response = await ListingFetchModel.checkTimer(authUser);
        if (!response.success) {
            if (response.error === "expired") {
                clearCart(cartItems).then((res) => {
                    handleShowToast("Your cart has expired", "failure");
                });
            }
        }
    };

    const clearCart = async (cartItems) => {
        ListingPostModel.clearCart(authUser, cartItems).then((response) => {
            if (response.success) {
                resetTimer();
            } else {
                handleRefresh();
            }
        });
    };

    const clearCartBackend = async () => {
        ListingPostModel.emptyCart(authUser).then((response) => {
            if (response.success) {
                resetTimer();
                handleRefresh();
            } else {
                handleRefresh();
            }
        });
    }

    const removeCartItem = async (item) => {
        handleShowToast("Removing from cart", "warning");
        ListingPostModel.removeFromCart(authUser, item).then((response) => {
            if (response.success) {
                ListingPostModel.resetTimer(authUser).then((response) => {
                    if (response.success) {
                        //handleShowToast("Removed from cart", "success");
                    } else {
                        //handleShowToast("Something went wrong", "failure");
                    }
                })
            } else {
                //handleShowToast("Something went wrong", "failure");
            }
            handleRefresh();
        })
    }

    const resetTimer = async () => {
        const res = await ListingPostModel.resetTimer(authUser);
        if (res.success) {
            handleRefresh();
        } else {
            handleShowToast("Something went wrong, please refresh and try again", "failure");
        }
    };

    const cartItems = cart.map((item) => (
        <Card key={item.key} sx={{ borderRadius: '16px', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)', overflow: 'visible', marginBottom: '16px' }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '24px' }}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {item.eventTitle}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#666', marginBottom: '4px' }}>
                        {new Date(item.eventStart).toLocaleString().slice(0,-3)} at {item.venue}
                    </Typography>
                        <p style={{ color: '#8146F6', fontWeight: 'bold', marginBottom: '8px', marginTop: "4px", display: 'block' }}>
                            {item.tickets.length} ticket{item.tickets.length > 1 ? 's' : ''}
                        </p>
                    <Box sx={{ display: "flex", flexDirection: 'column', marginTop: "6px"}}>
                        <Tooltip title="Remove this item from your cart">
                            <a href="#" underline="hover" style={{ color: '#FF6F61'}} onClick={() => removeCartItem(item)}>
                                Remove
                            </a>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Chip
                        label={`£${item.price.toFixed(2)}`}
                        color="primary"
                        sx={{
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            backgroundColor: '#8146F6',
                            color: '#fff',
                            borderRadius: '12px',
                            marginRight: '10px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    ));

    const buyCart = async () => {
        setLoading(true);
        const response = await ListingFetchModel.checkTimer(authUser);
        if (response.success && response.data > 0) {
            let response = await Transaction.getPaymentIntentClientSecret();
            if (response.success) {
                navigate("/cart/checkout?payment_intent_client_secret=" + response.data.clientSecret);
                setLoading(false);
            } else {
                setLoading(false);
                handleShowToast("Failed to checkout, please refresh the page and try again later", "failure");
            }
        } else {
            clearCartBackend();
            handleShowToast("Your cart is empty or expired", "failure");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoading && authUser) {
            getCart();
        }
    }, [isLoading, authUser, refresh]);

    return (
        <Container maxWidth="md" sx={{ minHeight: "90vh", paddingTop: '2rem', paddingBottom: '2rem' }}>
            {loading ? <Skeleton Type="circular" /> : (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
                            Cart
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                            <Typography variant="body1" sx={{ color: '#555', marginRight: '8px' }}>
                                Your tickets are reserved for 10 minutes 
                            </Typography>
                            <AccessTime sx={{ verticalAlign: 'middle', color: '#8146F6' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8}>
                        {cart.length === 0 ? (
                            <span className="cart-description" key="empty-cart">
                                Your cart is empty! Discover more events <a href="/" className="link">Here!</a>
                            </span>
                        ) : (
                            <Stack>
                                {authUser ? cartItems : <p key="notloggedin-cart">Please login to view your cart</p>}
                            </Stack>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                        <Card
                            sx={{
                                borderRadius: '16px',
                                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
                                padding: '24px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
                                Order Summary
                            </Typography>

                            {/* Breakdown of Order Total */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '8px' }}>
                                <Typography variant="body1">Ticket Price:</Typography>
                                <Typography variant="body1">£{((Math.round(total * 100))/100).toFixed(2)}</Typography>
                            </Box>

                            {/* Breakdown of Entick Fees */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '16px' }}>
                                <Typography variant="body1">Entick Fees:</Typography>
                                <Typography variant="body1">£{((Math.round(fee * 100))/100).toFixed(2)}</Typography>
                            </Box>

                            {/* Grand total */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '24px' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Grand Total:</Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>£{((Math.round((total * 100) + (fee * 100)))/100).toFixed(2)}</Typography>
                            </Box>

                            {/* Checkout button */}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    backgroundColor: '#8146F6',
                                    '&:hover': { backgroundColor: '#6D3AD1' },
                                    padding: '14px',
                                    borderRadius: '8px',
                                    fontWeight: 'bold',
                                }}
                                onClick={buyCart}
                                disabled={loading}
                            >
                                Checkout
                            </Button>
                        </Card>
                    </Grid>
                </Grid>
            )}
            </Container>
        )
}