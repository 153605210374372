import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebase/auth";
import "../../index.css"
import ListingPostModel from "../../models/postData/ListingPostModel";
import Authenticator from "../../models/Authenticator";
import ReviewBox from "../ReviewBox";
import ReviewFetchModel from "../../models/getData/ReviewFetchModel";
import { CaretDown, CaretUp } from "../Icons/Icons";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from '@mui/material/styles';

export default function TicketBuy({handleShowToast, listing, handleUnselect }) {
    const {authUser} = useAuth();
    const [count, setCount] = useState(1);
    const [username, setUsername] = useState("");
    const [avgRating, setAvgRating] = useState(0);
    const [numReviews, setNumReviews] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [reviewsVisible, setReviewsVisible] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();

    const getSellerInfo = async () => {
        if (listing) {
            Authenticator.getUserName(listing.createdBy).then((res) => {
                if (res.success) {
                    setUsername(res.data.username);
                    setAvgRating(res.data.avgRating);
                    setNumReviews(res.data.numReviews);
                }
            })
        }
    }

    async function CheckTicketCanBeAddedToCart() {
        if (authUser !== null) {
            await addToCart();
        }
    }

    async function addToCart() {
        setButtonDisabled(true);
        if (authUser !== null && listing.createdBy === authUser.uid) {
            handleShowToast("You can't buy your own tickets", "warning");
            setButtonDisabled(false);
            return;
        }
        let currListing = listing;
        handleShowToast("Adding to your cart and reserving for 10 mins", "success");
        handleUnselect();
        ListingPostModel.addToCart(authUser, currListing, count).then((response) => {
            if (response.success) {
                ListingPostModel.setTimer(authUser).then((response) => {
                    navigate('/cart');
                    window.scrollTo(0, 0);
                });
                setButtonDisabled(false);
                handleUnselect();
            } else {
                handleShowToast(response.error, "failure");
                setButtonDisabled(false);
            }
        })
    }

    function redirectToLogin() {
        handleShowToast("Please login to add tickets to cart. Redirecting to the login page...", "warning");
        navigate(`/login/buy?event=${listing.eventId}&listing=${listing.key}`);
    }

    function handleCountChange(event) {
        if (event.target.value <= listing.tickets.length) {
            setCount(event.target.value);
        } else {
            handleShowToast("Sorry, there are only " + listing.tickets.length + " ticket/s left by this seller", "failure");
        }
    }

    const getReviews = async () => {
        if (reviews.length === 0) {
            ReviewFetchModel.fetchReviews(listing.createdBy).then((response) => {
                if (response.success) {
                    setReviews(response.data);
                }
            })
        }
    }

    function showReviews() {
        setReviewsVisible(true);
    }

    const reviewCards = reviews.map((review) => {
        return (
            <ReviewBox key={review.id} name={review.isAnonymous ? "Anonymous" : (review.name)} rating={review.rating} text={review.text} />
        )
    })

    const dropwdownItems = [];
    for (let i = 1; i < listing.tickets.length+1; i++) {
        dropwdownItems.push(
            <MenuItem key={i} value={i}>{i}</MenuItem>
        );
    }

    const BootstrapButton = styled(Button)({
        textTransform: 'none',
        fontSize: 16,
        fontWeight: '600',
        backgroundColor: '#8146F6', // Keep the theme consistent
        color: '#fff',
        "&:hover": {
            backgroundColor: '#6D3AD1',
        },
        "&:disabled": {
            backgroundColor: '#ddd',
            color: '#888',
        },
    });

    useEffect(() => {
        getSellerInfo();
    }, [])

    useEffect(() => {
        if (reviewsVisible) {
            getReviews();
        }
    }, [reviewsVisible])

    return (
        <Stack spacing={2}>
            <Card variant="outlined" sx={{ padding: '16px', borderRadius: '12px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ rowGap: '16px' }}>
                <Select
                        labelId="number-of-tickets-label"
                        id="number-of-tickets"
                        value={count}
                        label="Tickets"
                        onChange={handleCountChange}
                        sx={{ borderRadius: '8px', minWidth: '25%' }}
                    >
                        {dropwdownItems}
                    </Select>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', minWidth: '25%' }}>£{(Number(listing.rawPrice) * count).toFixed(2)}</Typography>
                    <Typography variant="p" sx={{ textAlign: 'center', minWidth: '25%' }}>Verified Seller</Typography>
                </Box>
            </Card>
            <Card variant="outlined" sx={{ padding: '16px', borderRadius: '12px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    {buttonDisabled ? (
                        <BootstrapButton variant="contained" size="large" disabled sx={{ minWidth: '150px' }} >Add to Cart</BootstrapButton>
                    ) : (
                        <BootstrapButton
                            variant="contained"
                            size="large"
                            onClick={authUser ? CheckTicketCanBeAddedToCart : redirectToLogin}
                            sx={{ minWidth: '100%' }}
                        >
                            Add to Cart
                        </BootstrapButton>
                    )}
                </Box>
            </Card>

            {/* Note from Seller */}
            {listing.description !== "" && (
                <Card variant="outlined" sx={{ padding: '16px', borderRadius: '12px' }}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Note from seller:
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginTop: '8px' }}>
                            {listing.description}
                        </Typography>
                    </Box>
                </Card>
            )}

            {/* Seller Info and Reviews */}
            <Card variant="outlined" sx={{ padding: '16px', borderRadius: '12px' }}>
                <Box display="flex" alignItems="center">
                    <Avatar sx={{ marginRight: '10px' }}>{username.charAt(0).toUpperCase()}</Avatar>
                    <Box>
                        <Typography variant="h6" >
                            {username}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: '4px' }}>
                            <Rating name="reviews" value={avgRating} readOnly />
                            &nbsp;
                            <Typography variant="body2" color="text.secondary">
                                {numReviews} {numReviews > 1 || numReviews === 0 ? "reviews" : "review"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box padding="8px" marginLeft="auto" textAlign="right">
                    {reviewsVisible ? (
                        <Typography className="show-reviews" onClick={() => setReviewsVisible(false)} sx={{ cursor: 'pointer' }}>
                            Hide reviews <CaretUp />
                        </Typography>
                    ) : (
                        numReviews > 0 && (
                            <Typography className="show-reviews" onClick={showReviews} sx={{ cursor: 'pointer' }}>
                                Show reviews <CaretDown />
                            </Typography>
                        )
                    )}
                </Box>
            </Card>

            {/* Reviews Section */}
            {reviewsVisible && <Stack spacing={2}>{reviewCards}</Stack>}
        </Stack>
    );
}
