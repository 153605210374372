import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import "../index.css";
import '../firebaseui-styling.global.css';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { GoogleAuthProvider } from 'firebase/auth';
import { useAuth } from '../firebase/auth';
import { auth } from '../firebase/firebase';
import Container from "@mui/material/Container";
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { Button, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Typography, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { config } from "..";

export default function Login({handleShowToast}) {
    const { authUser, signOut } = useAuth();
    const navigate = useNavigate();
    const { redirect } = useParams();
    const location = useLocation();
    const [submitted, setSubmitted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [timer, setTimer] = useState(0);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const functions = getFunctions();
    const sendSignInEmailLink = httpsCallable(functions, 'sendSignInLink');
    
    // Regular expression for validating email format
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);

        // Validate the email and provide feedback
        if (!validateEmail(inputEmail)) {
        setEmailError('Please enter a valid email address.');
        } else {
        setEmailError('');
        }
    };

    const SignInUsingEmail = async () => {
        if (validateEmail(email)) {
            setIsClicked(true);
            try {
                await SignInUsingEmailLink();
            } catch (error) {
                handleShowToast("Error signing in, please try again", "error");
            } finally {
                setIsClicked(false);
            }
            //console.log('Sign-in with email link sent to:', email);
        } else {
            setEmailError('Invalid email address. Please correct it.');
            handleShowToast("Invalid email address", "failure");
        }
        setIsClicked(false);
    };

    // Resend Logic
    const handleResendEmail = async () => {
        // Disable the button and start the timer
        setIsDisabled(true);
        setTimer(30); // Disable for 30 seconds
    
        // Simulate API call to resend email
        await SignInUsingEmailLink();
    
        // Re-enable the button after the timer ends
        const countdown = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 1) {
              clearInterval(countdown);
              setIsDisabled(false);
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      };

    let REDIRECT_PAGE = '/';
    let emailRedirect = '';
    let searchParams = new URLSearchParams(location.search);
    if (redirect !== undefined) {
        if (redirect === "info") {
            emailRedirect = "?type=info"
            REDIRECT_PAGE = "/sellTickets/info";
        } else if (redirect === "buy") {
            emailRedirect = `?type=buy&event=${searchParams.get("event")}&listing=${searchParams.get("listing")}`;
            REDIRECT_PAGE = `/event/${searchParams.get("event")}#${searchParams.get("listing")}`;
        }
    }

    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: config.signIn_finish_url + emailRedirect,
        // This must be true.
        handleCodeInApp: true,
      };


    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: REDIRECT_PAGE,
        signInOptions: [
            {
                provider: GoogleAuthProvider.PROVIDER_ID,
                customParameters: {
                    prompt: 'select_account'
                }
           } 
            
        ],
        tosUrl: 'https://entick.co.uk/terms',
        privacyPolicyUrl: 'https://entick.co.uk/privacy',
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                if (authResult.additionalUserInfo.isNewUser) {
                    handleShowToast("Signed up successfully", "success");
                }
                if (REDIRECT_PAGE === "/sellTickets/info") {
                    navigate(REDIRECT_PAGE);
                } else if (redirect === "buy") {
                    navigate(REDIRECT_PAGE);
                }else {
                    navigate("/");
                }
                window.scrollTo(0, 0);

            },
            signInFailure: (error) => {
                handleShowToast("Error creating a user", "error");
            }

        }
    };

    const SignInButton = styled(Button) ({
        textTransform: 'none',
        background: 'linear-gradient(90deg, #8146F6, #25abef )',
        fontWeight: 'bold',
        fontSize: '14px',
        color: "white",
        ":disabled": {
            color: "#c0c0c0"
          },
        "&.MuiButton-outlined.Mui-disabled": {
        background: "#e4e4e4",
        color: "#b7babf"
        },
        "&.MuiButton-contained.Mui-disabled": {
        background: "#eaeaea",
        color: "#c0c0c0"
        }
    })

    const ResendButton = styled(Button) ({
        textTransform: 'none',
        fontSize: '14px',
        color: "white"
    })

    // Sends the email to sign up
    const SignInUsingEmailLink = async () => {
        await sendSignInEmailLink({ email: email, redirectUrl: actionCodeSettings.url })
        .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email);
            setSubmitted(true);
            // ...
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setSubmitted(false);
        });
        }

    let container;
    if (!submitted) {
        container = (
            <Stack spacing={2}>
                <div>
                    <h1>Sign In</h1>
                    <p className="login-description">If you already have an account, we'll log you in</p>
                </div>
                <div className="login-email-container">
                    <FormControl variant="standard">
                    <TextField
                        size="small"
                        variant="outlined"
                        id="email-text-field"
                        placeholder="Type your email..."
                        aria-describedby="email-text-field"
                        value={email}
                        type="email"
                        onChange={handleEmailChange}
                        error={!!emailError}
                        helperText={emailError}
                    />
                    </FormControl>
                </div>
                <SignInButton 
                    variant="contained" 
                    onClick={SignInUsingEmail}  // Disable if email is empty or invalid
                    disabled={isClicked}
                >
                    Sign In with Email
                </SignInButton>
                <div className="or-wrap">
                    <div className="or-line"></div>
                    <div className="or-text">or</div>
                    <div className="or-line"></div>
                </div>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </Stack>
        );
    } else {
        container = (
            <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 10 }}>
                <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
                    <EmailIcon color="primary" sx={{ fontSize: 60 }} />
                    <Typography variant="h5" component="h1" gutterBottom>
                    Check Your Email
                    </Typography>
                    <Typography variant="body1" paragraph>
                    We've sent you an email with a link to sign in. Please check your inbox and follow the instructions to complete the sign-in process.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                    Didn't receive the email? Make sure to check your spam folder or click the button below to resend the email.
                    </Typography>
                    <ResendButton
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ mt: 2 }}
                        onClick={handleResendEmail}
                        disabled={isDisabled}
                        >
                        {isDisabled ? `Resend in ${timer}s` : "Resend Email"}
                        </ResendButton>
                </Box>
            </Container>
        );
    }
    

    if (!authUser) {
        return (
            <Container maxWidth='xs' sx={{ minHeight: '90vh', maxWidth: '408px', minWidth: '346px'}}>
                {container}
            </Container>
        )
    } else {
        return (
            <Container maxWidth='sm' sx={{ minHeight: '90vh', maxWidth: '408px', minWidth: '346px'}}>
                <div className="login-container">
                    <h1>You are Already Logged in</h1>
                    <SignInButton variant="contained" onClick={() => navigate("/")} >Go to the Home page</SignInButton>
                </div>
            </Container>
        )
    }
}
