import React from "react";
import '../index.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

export default function ReviewBox(props) {

    return (
        <Card variant="outlined" sx={{ width: "100%"}}>
            <Box component="div" display="flex" alignItems="center" justifyContent="start" flexDirection="row" p={1}>
            <Avatar sx={{ marginRight: '10px'}} >{props.name.charAt(0)}</Avatar>
                <Box>
                    <Typography variant="h6" color="text.primary">
                        {props.name}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center"}}>
                        <Rating name="reviews" value={props.rating} readOnly />
                    </Box>
                </Box>
            </Box>
            <div className="review-box-body">
                <p className="review-box-body-text">{props.text}</p>
             </div>
        </Card>
    );
}