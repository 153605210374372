import React, { useEffect, useState } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { getStorage, ref, getDownloadURL, updateMetadata } from "firebase/storage";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import "../../index.css"
import EventFetchModel from "../../models/getData/EventFetchModel";
import { listingToObject } from "../../models/getData/ListingFetchModel";
import ListingFetchModel from "../../models/getData/ListingFetchModel";
import Listing from "./Listing";
import { CalendarIcon, LocationDot } from '../Icons/Icons';
import Skeleton from "../Skeleton/Skeleton";
import TicketBuy from "./TicketBuy";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import defaultImg from "../../images/ministry.webp"

export default function Event({ handleShowToast }) {
    const [event, setEvent] = useState({})
    const [listings, setListings] = useState([])
    const [timeString, setTimeString] = useState("");
const [imageUrl, setImageUrl] = useState(defaultImg);
    const [listingSelected, setListingSelected] = useState(null);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const storage = getStorage();

    const getImages = async (venuePhotoName, venueId) => {
        if (venuePhotoName === undefined || venuePhotoName === null) {
            setImageUrl(defaultImg);
            return;
        }
        // getting the url from session storage cache
        let venueUrl = sessionStorage.getItem(venueId);
        if (venueUrl !== null) {
            setImageUrl(venueUrl);
            return;
        }

        const imageRef = ref(storage, venuePhotoName);
        if (venueUrl === null) {
            // set up caching for storage
            const newMetadata = {
                cacheControl: 'public,max-age=6000'
            };
    
            // Update metadata properties
            await updateMetadata(imageRef, newMetadata);
        }

        const url = await getDownloadURL(imageRef);
        sessionStorage.setItem(venueId, url);
        setImageUrl(url);
    }

    const getEventandListings = async () => {
        if (event) {
            const _event = EventFetchModel.fetchEvent(id);
            const _listings = ListingFetchModel.fetchListings(id);
            const responses = await Promise.all([_event, _listings]);
            if (responses[0].success && responses[1].success) {
                setEvent(responses[0].data);
                setListings(responses[1].data);
                await getImages(responses[0].data.venuePhotoName, responses[0].data.venueId);
                let startTime = "";
                startTime = new Date(responses[0].data.start);
                setTimeString(startTime.toLocaleString().slice(0, -10));
            } else if (responses[0].error === "Event expired") {
                handleShowToast("Event expired", "failure");
                navigate("/");

            } else {
                handleShowToast("Error getting event and listings", "failure");
            }
        }
        setLoading(false);
    }

    function handleSelect(sListing) {
        navigate(`#${sListing.key}`);
        setListingSelected(sListing);
    }

    function handleUnselect() {
        navigate(`#`);
        setListingSelected(null);
    }

    let verifiedExist = false;
    const listingCards = listings.map((listing) => {
        if (listing.tickets.length !== 0) {
            verifiedExist = true;
            return (
                <Listing key={listing.key} id={listing.key} item={listing} disabled={false} handleSelect={handleSelect}/>
            )
        }
        return;
    })

    let container;
    if (listingSelected !== null) {
        container = (
            <Box sx={{ minHeight: 1, marginTop: '4px !important' }} paddingBottom={20}>
                <Container maxWidth="sm" sx={{ minHeight: 1 }}>
                    <h2 className="titles-2">Buy Tickets</h2>
                    <TicketBuy listing={listingSelected} event={event} handleShowToast={handleShowToast} handleUnselect={handleUnselect} />
                    <Box my={1}>
                        <Button variant="outlined" className="back-button" onClick={handleUnselect}>Back</Button>
                    </Box>
                </Container>
            </Box>
        )
    } else {
        container = (
            <Box sx={{ minHeight: 1, marginTop: '4px !important' }} paddingBottom={20}>
                <Container maxWidth="sm" sx={{ minHeight: 1 }}>
                    <h2 className="titles-2">Available Tickets</h2>
                    <Stack spacing={1} paddingTop={5}>
                        {verifiedExist ? listingCards : <Typography variant="h7">There are no available listings for this event</Typography>}
                    </Stack>
                </Container>
            </Box>
        )
    }

    useEffect(() => {
        getEventandListings();
    }, [])

    useEffect(() => {
        const q =  query(collection(db, "listing"), where('event', '==', id), where('verified', "==", true));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let snapshotListings = querySnapshot.docs.map((doc) => listingToObject(doc));
            setListings(snapshotListings);
        })

        return () => unsubscribe();
    }, []);


    return (
        <React.Suspense fallback={<Skeleton Type="circular" />}> 
            <Box sx={{ minHeight: "95vh", paddingBottom: 10 }}>
                {loading ? (<Skeleton Type="event" />) : (
                    <Stack spacing={2} >
                        <div className="event-hero">
                            <img className="event-image" alt="event-image" src={imageUrl} height="210px" width="350px" />
                            <h1 className="event-title">{event.name}</h1>
                            <div style={{ paddingTop: "0.5rem" }}>
                                <span className="event-date"><CalendarIcon /> {timeString}</span>
                                <span className="event-location"><LocationDot /> {event.venue}</span>
                            </div>
                        </div>
                        <Divider sx={{ my: 2 }} />
                        {container}
                    </Stack>
                )}
            </Box>
        </React.Suspense>
    )
}
