import React, { useState} from 'react';
import { useRange } from 'react-instantsearch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel } from '@mui/material';

const unsetNumberInputValue = '';

export default function DateSelect() {
    const { start, range, canRefine, precision, refine } = useRange({
        attribute: "start",
        min: 0,
        max: 1000719792000000,
        precision: 1,

    });
    const [date, setDate] = useState("");
    const [dateScope, setDateScope] = useState([0, 0]);
    const step = 1 / Math.pow(10, precision || 0);
    const values = {
      min:
        start[0] !== -Infinity && start[0] !== range.min
          ? start[0]
          : unsetNumberInputValue,
      max:
        start[1] !== Infinity && start[1] !== range.max
          ? start[1]
          : unsetNumberInputValue,
    };
    const [prevValues, setPrevValues] = useState(values);

    const items = [
    {"value": "Anytime",
    "label": "Anytime"},
    {"value": "Today",
    "label": "Today"},
    {"value": "Tomorrow",
    "label": "Tomorrow"},
    {"value": "Weekend",
    "label": "This Weekend"},
    {"value": "Week",
    "label": "This Week"},
    {"value": "NextWeek",
    "label": "Next Week"},
    {"value": "Month",
    "label": "This Month"}
    ];
  
    const [{ from, to }, setRange] = useState({
      from: values.min?.toString(),
      to: values.max?.toString(),
    });
  
    if (values.min !== prevValues.min || values.max !== prevValues.max) {
      setRange({ from: values.min?.toString(), to: values.max?.toString() });
      setPrevValues(values);
    }

    function handleDateScope(event) {
        const oneDay = 86400000;
        const now = new Date().getTime();
        let startOfDay = now - (now % oneDay);
        let day = new Date().getDay();
        let startOfWeek = startOfDay - (day * oneDay);
        if (event.target.value === "Today") {
            setDateScope([startOfDay, startOfDay + oneDay]);
            setRange({from: startOfDay.toString(), to: (startOfDay + oneDay).toString()});
            refine([startOfDay, startOfDay + oneDay]);
        } else if (event.target.value === "Tomorrow") {
            setDateScope([startOfDay + oneDay, startOfDay + (2 * oneDay)]);
            setRange({from: (startOfDay + oneDay).toString(), to: (startOfDay + (2 * oneDay)).toString()});
            refine([startOfDay + oneDay, startOfDay + (2 * oneDay)]);
        } else if (event.target.value === "Weekend") {
            setDateScope([startOfWeek + (5 * oneDay), startOfWeek + (8 * oneDay)]);
            refine([startOfWeek + (5 * oneDay), startOfWeek + (8 * oneDay)]);
        } else if (event.target.value === "Week") {
            setDateScope([startOfWeek, startOfWeek + (8 * oneDay)]);
            setRange({from: startOfWeek.toString(), to: (startOfWeek + (8 * oneDay)).toString()})
            refine([startOfWeek, startOfWeek + (8 * oneDay)]);
        } else if (event.target.value === "NextWeek") {
            setDateScope([startOfWeek + (8 * oneDay), startOfWeek + (15 * oneDay)]);
            setRange({from: (startOfWeek + (8 * oneDay)).toString(), to: (startOfWeek + (15 * oneDay)).toString()});
            refine([startOfWeek + (8 * oneDay), startOfWeek + (15 * oneDay)]);
        } else if (event.target.value === "Month") {
            setDateScope([startOfDay, startOfDay + (30 * oneDay)]);
            setRange({ from: startOfDay.toString(), to: (startOfDay + (30 * oneDay)).toString()});
            refine([startOfDay, startOfDay + (30 * oneDay)]);
        } else {
            setDateScope([now, range.max]);
            setRange({from: now.toString(), to: range.max.toString()});
            refine([now, range.max]);
        }
        setDate(event.target.value);
    };

    return (
        <FormControl fullWidth={true} size='small' sx={{height: "50px"}}>
            <InputLabel id="date-select-label" >Date</InputLabel>
            <Select
            labelId='date-select-label'
                id="date-select"
                label="Date"
                value={date}
                onChange={(event) => {
                    event.preventDefault();
                    handleDateScope(event);
                  }}
            >
                {items.map((item) => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
  }