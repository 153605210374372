import React from 'react';
import { InstantSearch, SearchBox, Hits, useInstantSearch } from 'react-instantsearch';
import { Grid } from '@mui/material';
import DateSelect from '../DateSelect';

let timerId = undefined;
let timeout = 250;

export default function SearchBar({ searchClient, hitComponent }) {

    function queryHook(query, search) {
        if (timerId) {
          clearTimeout(timerId);
        }
      
        timerId = setTimeout(() => search(query), timeout);
      }

      function NoResultsBoundary({ children, fallback }) {
        const { results } = useInstantSearch();
      
        if (!results.__isArtificial && results.nbHits === 0) {
          return (
            <>
              {fallback}
              <div hidden>{children}</div>
            </>
          );
        }
      
        return children;
      }
      
      function NoResults() {
        const { indexUiState } = useInstantSearch();
      
        return (
          <div>
            <p>
              No results for <q>{indexUiState.query}</q> in the selected time range.
            </p>
          </div>
        );
      }

    return (
        <InstantSearch searchClient={searchClient} indexName="entick_events" insights>
            <Grid container spacing={1}>
                <Grid item xs={9} md={10}>
                    <SearchBox
                    placeholder='Search for events'
                    queryHook={queryHook}
                    />
                </Grid>
                <Grid item xs={3} md={2}>
                    <DateSelect/>
                </Grid>
            </Grid>
            <Grid item sx={{minHeight: "40vh"}}>
                <NoResultsBoundary fallback={<NoResults />}>
                    <Hits hitComponent={hitComponent} />
                </NoResultsBoundary>
            </Grid>
        </InstantSearch>
    )
}